<template>
  <div v-if="card" class="h-full bg-white font-anomaliaRegular bg-repeat-y">
    <div class="pile">
      <img
        class="h-auto max-w-full m-auto cursor-pointer"
        :src="pileCardsUrl"
        alt="pile_cards"/>
      <vue-flip
        v-model="flipped"
        class="cursor-pointer flex flex-col custom-size overflow-hidden rounded-lg m-auto"
        width="96%"
        height="97%"
        @click="handleFlip">
        <!--  eslint-disable-next-line vue/no-useless-template-attributes -->
        <template #front class="h-auto max-w-full">
          <div
            class="p-5 lg:p-8 flex flex-col justify-around items-center border-7 border-cardGame text-center bg-white h-full max-w-full rounded-xl">
            <div
              class="pt-4 lg:pt-8 flex flex-col text-xl lg:text-5xl text-[#170965] font-medium">
              <span class="">{{ card.options[0] }}</span>
              <span class="text-base lg:text-xl">או</span>
              <span class="">{{ card.options[1] }}</span>
            </div>
            <img :src="imageUrl" class="w-35 pt-5" alt="card image" />
          </div>
        </template>
        <template #back>
          <img class="m-auto cursor-pointer" :src="back" alt="pile_cards" />
        </template>
      </vue-flip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { collect } from 'collect.js';
import { VueFlip } from 'vue-flip';
import back from '/@/assets/card_game/back.svg?url';

const flipped = ref<any>(true);

const data = {
  front: [
    {
      icon: 'question_1',
      options: ['יומיים בגולן', 'יומיים בתל אביב'],
    },
    {
      icon: 'question_2',
      options: ['שחמט', 'שש-בש'],
    },
    {
      icon: 'question_3',
      options: ['לשאול', 'לענות'],
    },
    {
      icon: 'question_4',
      options: ['קריוקי', 'מסיבת ריקודים'],
    },
    {
      icon: 'question_5',
      options: ['אייפון', 'אנדרואיד'],
    },
    {
      icon: 'question_6',
      options: ['תנ”ך', 'מתמטיקה'],
    },
    {
      icon: 'question_7',
      options: ['לארגן טיול', 'לארגן את האוכל'],
    },
    {
      icon: 'question_8',
      options: ['לרוץ לבד', 'בקבוצה'],
    },
    {
      icon: 'question_9',
      options: ['טיקטוק', 'אינסטגרם'],
    },
    {
      icon: 'question_10',
      options: ['תל אביב', 'ירושלים'],
    },
    {
      icon: 'question_11',
      options: ['נועה קירל', 'חנן בן ארי'],
    },
    {
      icon: 'question_12',
      options: ['כלב', 'חתול'],
    },
    {
      icon: 'question_13',
      options: ['הפסקה בחוץ', 'הפסקה בפנים'],
    },
    {
      icon: 'question_14',
      options: ['לשבת בסלון', 'בחדר'],
    },
    {
      icon: 'question_15',
      options: ['ספר', 'סרט'],
    },
    {
      icon: 'question_16',
      options: ['החבר’ה מהכיתה', 'מהסניף'],
    },
    {
      icon: 'question_17',
      options: ['אוזניות', 'רמקול'],
    },
    {
      icon: 'question_18',
      options: ['תנועת נוער', 'התנדבות במד”א'],
    },
    {
      icon: 'question_19',
      options: ['שיעור בזום', 'בכיתה'],
    },
    {
      icon: 'question_20',
      options: ['לזרוק זבל', 'לסדר את החדר'],
    },
  ],
  back: 'back',
};
const card = ref(collect(data.front).shuffle().take(1).first());

const imageUrl = computed(() => {
  return new URL(
    `/src/assets/card_game/${card.value.icon}.svg`,
    import.meta.url,
  ).href;
});
const pileCardsUrl = computed(
  () => new URL('/src/assets/card_game/pile_cards.svg', import.meta.url).href,
);
const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

let flippedCards: any = [];
const handleFlip = async () => {
  flipped.value = !flipped.value;
  if (flipped.value) {
    await sleep(500);
    if (flippedCards.length >= data.front.length) {
      flippedCards = [];
    }

    card.value = collect(data.front)
      .whereNotIn('icon', flippedCards)
      .shuffle()
      .take(1)
      .first();
    flippedCards.push(card.value.icon);
  }
};
</script>
<style scoped>
.bg-back {
  background-size: cover;
  background-position: center;
}

.pile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.front {
  width: inherit;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.custom-size {
  position: absolute;
  left: -3px;
  bottom: 0;
  overflow: inherit;
}
</style>
